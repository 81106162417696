function recaptchaCallback() {
    $('#c_recaptcha').valid();
}

;(function(window, document, $) {
    var $win = $(window);
    var $doc = $(document);

    //Open navigation
    $('.nav-trigger').on('click', function(event) {
        $(this).toggleClass('nav-trigger--active');
        $('.header__content').toggleClass('visible');

        event.preventDefault();
    });

    $.validator.addMethod("validatePostCode", function (value, element) {
        var reg = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/;
        return reg.test(value);
    }, "Code postal invalide");

    $.validator.addMethod("validateDate", function (value, element) {
        var reg = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        return reg.test(value);
    }, "Date invalide");

    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#guaranty_cgv').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    

    setTimeout(function () {
        
        if ($('.contact_form').length > 0) {
            $('#guaranty_cgv').rules('add', {
                validateCgv: true
            });
        }
        $('#f_recaptcha').rules('add', {
            validateRecaptcha: true
        });


    }, 500);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide.",
        url: "Veuillez fournir une adresse URL valide.",
        number: "Veuillez fournir un numéro valide.",
        digits: "Veuillez fournir seulement des chiffres."
    });

    $doc.ready(function() {
        inlineBg();
        productsHeight();

        if ($('#added_btn_light').length) {
            $('#added_btn_light').trigger('click');
        }

        // Price Change
        var formVariation = $('form.variations_form');
        var configurablePrice = {};
        if (formVariation.length) {
            var variableConfig = formVariation.attr('data-product_variations');
            variableConfig = variableConfig ? JSON.parse(variableConfig) : false;
            $.each(variableConfig, function( index, _config ) {
                if (_config.attributes.attribute_pa_quantity && _config.display_price) {
                    var totalAmount  = _config.display_price.toLocaleString();
                    var totalPrice  = _config.display_price.toLocaleString() + woodstockCurrency;
                    var totalPriceKg  = _config.display_price/_config.attributes.attribute_pa_quantity;
                    totalPriceKg = parseFloat(totalPriceKg);
                    totalPriceKg = totalPriceKg/woodstockUnitPrice;
                    totalPriceKg = Math.round(totalPriceKg*100)/100;
                    totalPriceKg  = totalPriceKg ? totalPriceKg.toLocaleString() + woodstockCurrency : '';

                    var linePrice = _config.display_price/_config.attributes.attribute_pa_quantity;
                    linePrice = linePrice.toLocaleString() + woodstockCurrency;

                    var _lineConfig = {
                        'id': _config.variation_id,
                        'amount': totalAmount,
                        'currency': woodstockCurrency,
                        'price': totalPrice,
                        'weight': totalPriceKg,
                        'line': linePrice
                    }
                    if ($('#pa_dimension').length) {
                        configurablePrice[_config.attributes.attribute_pa_quantity+'__'+_config.attributes.attribute_pa_dimension] = _lineConfig;
                    }
                    else {
                        configurablePrice[_config.attributes.attribute_pa_quantity] = _lineConfig;
                    }
                }
            });
        }

        setTimeout(function(){
            $('select#pa_quantity').trigger( "change" );
            $('select#pa_quantity').change();
        }, 50);

        
        $('select#pa_quantity, select#pa_dimension').on('change', function() {
            if ($('#pa_dimension').length) {
                var confVal = $('#pa_quantity').val()+'__'+$('#pa_dimension').val();
            }
            else {
                var confVal = $(this).val();
            }
            if (configurablePrice && configurablePrice[confVal]) {
                $('input.variation_id').val(configurablePrice[confVal].id);
                $('#product_price_box').html('<span class="price" itemprop="price" content="'+configurablePrice[confVal].amount+'">'+configurablePrice[confVal].amount+'</span> <span itemprop="priceCurrency" content="EUR">'+configurablePrice[confVal].currency+'</span>');
                $('#price_kg_value').html(configurablePrice[confVal].weight);
                if ($('#pa_quantity').val() >= 2) {
                    $('#product_line_price_box').removeClass('hidden');
                }
                $('#product_line_price').html(configurablePrice[confVal].line);
            }
            else {
                $('#product_price_box').html('-');
                $('#price_kg_value').html('-');
                $('#product_line_price_box').addClass('hidden');
            }
        });


        

        $('.contact_form').validate({
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form__row');
                    error.addClass("checkbox");
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form__controls").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form__controls").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                form.submit();
            }
        });
        


    });
    //Open profile dropdown
    // $('.profile > a').on('click', function(event) {
    //     $(this).parent().find('ul').slideToggle();

    //         event.preventDefault();
    // });

    //Selectric
    $('.selectric, #billing_country').selectric({
        disableOnMobile: false,
        nativeOnMobile: false
    });

    //Slider images
    $('.slider--images .slider__slides').slick({
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
    });
    var sSlidecount = $('.slider--images .slider__slides .slider__slide').length - 2;
    if (sSlidecount < 2) {
      $('.slider--images .slider__slides .slick-dots').remove();
    }

    //Animated label
    $('.field, .textarea').on('input', function() {
        var $this = $(this);
        var isPopulated = $this.val() !== '';

       $this.prev().toggleClass('form__label--active', isPopulated);
    });

    // Accordion
    $('.accordion__head').on('click', function(event) {
        event.preventDefault();

        var $this = $(this);
        var $accordion = $this.closest('.accordion__section');

        $accordion.find('.accordion__body').slideToggle();
        $accordion.siblings().find('.accordion__body').slideUp();

        $accordion.toggleClass('active');
        $accordion.siblings().removeClass('active');
    });

    $('.scroll-tab').on('click', function() {
        var scTo = $(this).attr('data-target');
        if (scTo) {
            $('html, body').animate({
                scrollTop: $(scTo).offset().top - 50
            }, 800);
        }

    });

    // $('.product .product__image > a').on('click', function(event) {
    //     $(this).parent().find('.product__image-inner').toggleClass('visible');

    //     event.preventDefault();
    // });

    function inlineBg() {
        $("[data-bg]").each(function () {
            var dataImg = $(this).data('bg');
            $(this).css('background-image', 'url(' + dataImg + ')');
        });
    }

    function productsHeight() {
        $('.section--products').each(function() {
            var productsElms = $(this).find('.products');
            var maxPH = 0;
            productsElms.find('li.product').each(function() {
                var prodPH = $(this).find('.product__content p').height();
                if (prodPH > maxPH) {
                    maxPH = prodPH;
                }
            });
            productsElms.find('li.product .product__content p').height(maxPH);
        });
    }

})(window, document, window.jQuery);
